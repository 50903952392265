import React, { createContext, useCallback, useEffect, useMemo, useReducer } from 'react';

// third-party
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import { Avatar, Typography } from '@mui/material';
import { SaveOutlined } from '@ant-design/icons';
import notificationReducer, { notificationApi, useLazyGetNotificationListQuery } from 'store/reducers/notification';
import { NotificationContextType, NotificationProps } from 'types/notification';
import { DELETETOKEN, GETTOKEN, READCOUNT } from 'store/reducers/actions';
import { useModal } from "mui-modal-provider";
import NotificationDialog from 'components/NotificationDialog';
import downloadFile from 'helper/downloadFile';
import { useEnv } from 'hooks/useEnv';

const isSupported = () =>
    typeof window !== "undefined" &&
    'Notification' in window &&
    typeof Notification.requestPermission === 'function' &&
    'serviceWorker' in navigator &&
    'PushManager' in window

const initialState: NotificationProps = {
    token: null,
    read: 0,
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

export const NotificationContext = createContext<NotificationContextType | null>(null);

export const NotificationProvider = ({ children }: { children: React.ReactElement }) => {
    const { envData } = useEnv()
    useEffect(() => {
      if (!firebase.apps.length) {
        firebase.initializeApp({
          apiKey: envData?.REACT_APP_FIREBASE_API_KEY,
          authDomain: envData?.REACT_APP_FIREBASE_AUTH_DOMAIN,
          projectId: envData?.REACT_APP_FIREBASE_PROJECT_ID,
          storageBucket: envData?.REACT_APP_FIREBASE_STORAGE_BUCKET,
          messagingSenderId: envData?.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
          appId: envData?.REACT_APP_FIREBASE_APP_ID,
        });
      }
    }, [envData])

    const getMessaging = useMemo(() => {
      return firebase.apps.length ? firebase.messaging() : null;
    }, [firebase.apps.length]);

    const [state, dispatch] = useReducer(notificationReducer, initialState);

    const [trigger] = notificationApi.endpoints.downloadErrorFile.useLazyQuery()

    const triggerDownloadButton = useCallback(
        (id: string) => trigger(id),
        [trigger],
    )

    const [getAllNotificationsCall] = useLazyGetNotificationListQuery()


    const { showModal } = useModal();

    const getToken = () => {
        const fireBaseToken = localStorage.getItem("x-firebase-token")
        // const path = process.env.REACT_APP_ENV === "development" ? "/firebase-messaging-sw.js" :
        // "/firebase-messaging-sw-stag.js"
        if (!fireBaseToken) {
            if ("serviceWorker" in navigator) {
                navigator.serviceWorker
                    .register("/firebase-messaging-sw.js")
                    .then(function (registration) {
                        registration.active?.postMessage({
                            type: "INIT_FIREBASE_CONTEXT",
                            payload: {
                                apiKey: envData?.REACT_APP_FIREBASE_API_KEY,
                                authDomain: envData?.REACT_APP_FIREBASE_AUTH_DOMAIN,
                                projectId: envData?.REACT_APP_FIREBASE_PROJECT_ID,
                                storageBucket: envData?.REACT_APP_FIREBASE_STORAGE_BUCKET,
                                messagingSenderId: envData?.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
                                appId: envData?.REACT_APP_FIREBASE_APP_ID,
                            },
                        })
                        getMessaging?.getToken({
                            vapidKey: envData?.REACT_APP_FIREBASE_VALIDKEY,
                            serviceWorkerRegistration: registration
                        })
                            .then((token) => {
                                if (token) {
                                    localStorage.setItem("x-firebase-token", token)
                                    dispatch({ type: GETTOKEN, payload: { token } })
                                    // Track the token -> client mapping, by sending to backend server
                                    // show on the UI that permission is secured
                                } else {
                                    console.log('No registration token available. Request permission to generate one.');
                                    // shows on the UI that permission is required 
                                }
                            }).catch((err) => {
                                console.log('An error occurred while retrieving token. ', err);
                            });
                    })
                    .catch(function (err) {
                        console.log("Service worker registration failed, error:", err);
                    });
                //     getMessaging.getToken({
                //         vapidKey: "BAKqJKYEqdZ0vesTfm6_hxRUvYSCKUC3DKK3xelbaq_OnLvpBvwszUolUIzGCmD3KDw9CCLuA6rdgXnOF4rOXbM",
                //         serviceWorkerRegistration: navigator.serviceWorker.register()
                // }).then(token => {
                //             console.log("firebase token", token)
                //             localStorage.setItem("x-firebase-token", token)
                //             dispatch({ type: GETTOKEN, payload: { token } })
                //         }).catch(err => {
                //             console.log("firebase token error", err)
                //         })
            }
        }
    }

    const deleteToken = () => {
        getMessaging?.deleteToken().then(val => val).catch(err => err)
        localStorage.removeItem("x-firebase-token")
        dispatch({ type: DELETETOKEN })
    };

    const setRead = (read: number) => {
        dispatch({ type: READCOUNT, payload: { read } })
    };

    const onHandleNotification = useCallback(
        async ({ title, body: NotificationBody }: { title: string, body: string }) => {
            const NotificationBodyAll = JSON.parse(NotificationBody)
            const read = state.read ? state.read + 1 : 1
            dispatch({ type: READCOUNT, payload: { read } })
            getAllNotificationsCall({
                pageNo: 1,
                size: 5,
                searchText: "",
            })
            // if (NotificationBodyAll.errorCount > 0) {
            //     await triggerDownloadButton(NotificationBodyAll.id)
            // }

            const { data } = await triggerDownloadButton(NotificationBodyAll.id)
            const downloadLink = NotificationBodyAll.errorCount > 0 ?
                `data:text/csv;base64,${data?.data.fileData}` : null;
            const downloadName = data?.data.fileName || "download";
            const body = <div>
                <Typography color={"green"}>Success: {NotificationBodyAll.successCount}</Typography>
                <Typography color={"red"}>Errors: {NotificationBodyAll.errorCount}</Typography>
            </div>
            const modal = showModal(NotificationDialog, {
                title,
                body,
                downloadLink,
                icon: (
                    <Avatar
                        color="warning"
                        sx={{ width: 72, height: 72, fontSize: "1.75rem" }}
                    >
                        <SaveOutlined />
                    </Avatar>
                ),
                onTapYes: () => {
                    if (downloadLink) downloadFile(downloadLink, downloadName)
                    modal.hide();
                },
                onTapNo: () => {
                    modal.hide();
                },
            });
        },
        [getAllNotificationsCall, showModal, state.read, triggerDownloadButton]);


    // useEffect(() => {
    //     const newCount = state.read ? state.read + 1 : 1
    //     setRead(newCount)
    // }, [isSuccess])

    const notification = useMemo(() => ({ ...state, setRead }), [state])

    useEffect(() => {
        if (isSupported()) {
            switch (Notification.permission) {
                case "default":
                    Notification.requestPermission()
                        .then((permission) => {
                            if (permission === 'granted') {
                                getToken()
                            } else {
                                deleteToken()
                            }
                        }).catch(() => deleteToken()).catch(err => err)
                    break;
                case "granted":
                    getToken()
                    break;
                case "denied":
                    deleteToken()
                    break;
                default:
                    getToken()
                    break;
            }
            getMessaging?.onMessage(function (payload) {
                const { notification } = payload
                onHandleNotification(notification)

                new Notification(notification.title, notification.body)
            })
        }
    }, [onHandleNotification])


    return (
        <NotificationContext.Provider
            value={notification}
        >
            {children}
        </NotificationContext.Provider>
    );
};

export default NotificationContext;
