import React, { createContext, useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import axiosServices from "utils/axios";
import { ApiConstant } from "constants/ApiConstants";
import { getENVMapping } from "helper/general";

// Define types
interface DecodedTokenPayload {
  [key: string]: any;
}

interface EnvContextType {
  envData: DecodedTokenPayload | null;
  isENVLoading: boolean;
}

// Create Context
export const EnvContext = createContext<EnvContextType>({ envData: null, isENVLoading: true });

export const EnvProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [envData, setEnvData] = useState<DecodedTokenPayload | null>(null);
  const [isENVLoading, setIsENVLoading] = useState(true);

  useEffect(() => {
    const fetchEnvData = async () => {
      try {
        const appENV = getENVMapping()
        const { data } = await axiosServices.get(`${ApiConstant.GET_ENV}?ENV=${appENV}`);

        // Decode token
        const decodedToken: DecodedTokenPayload = jwtDecode(data.data);
        const decodedBase64String: string = atob(decodedToken.secrets);
        const decodedBase64: DecodedTokenPayload = JSON.parse(decodedBase64String);

        setEnvData(decodedBase64); // Store in memory
      } catch (error: any) {
        console.error("Error loading env data:", error.response?.data || error.message);
      } finally {
        setIsENVLoading(false);
      }
    };

    fetchEnvData();
  }, []);

  return <EnvContext.Provider value={{ envData, isENVLoading }}>{children}</EnvContext.Provider>;
};
