import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// third-party
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

// scroll bar
import "simplebar/dist/simplebar.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// apex-chart
import "assets/third-party/react-table.css";

// project import
import App from "./App";
import { store, persister } from "store";
import { ConfigProvider } from "contexts/ConfigContext";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";

import "assets/css/eco_style.css";
import "assets/css/login.css";
import Error500 from "pages/maintenance/500";

import { EnvProvider } from "contexts/EnvProivder";
import { useEnv } from "hooks/useEnv";
import LottieLoader from "components/LottieLoader";
import React from "react";

const RootComponent = () => {
  const { envData, isENVLoading } = useEnv();

  React.useEffect(() => {
    Sentry.init({
      dsn: envData?.REACT_APP_SENTRY_KEY,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      environment: envData?.REACT_APP_ENV,
      ignoreErrors: ["^(Token is (expired|invalid))$", "ApolloError"],
      release: envData?.REACT_APP_SENTRY_RELEASE_VERSION,
    });
  }, [])

    // ==============================|| MAIN - REACT DOM RENDER  ||============================== //
// const UnSecuredPageWarning = () => (
//   <div>
//     <h1>
//       If you see this page, Webb App link you have clicked on is under
//       Clickjacking security attack.
//     </h1>
//     <h2>
//       Please inform team with the reference of the application from where you
//       clicked this link
//     </h2>
//   </div>
// );
// Won't render the application if WebApp is under Clickjacking attack
// if (window.self === window.top) {
  if (isENVLoading) return <LottieLoader />;
  return (
    <Auth0Provider
      domain={envData?.REACT_APP_AUTH_DOMAIN ?? ""}
      clientId={envData?.REACT_APP_AUTH_CLIENT_ID ?? ""}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: envData?.REACT_APP_AUTH_AUDIENCE,
        scope:
          "profile read:current_user update:users_app_metadata update:users update:current_user_metadata openid email read:roles",
      }}
      cacheLocation="localstorage"
      useRefreshTokens
      useRefreshTokensFallback={true}
    >
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persister}>
          <ConfigProvider>
            <Sentry.ErrorBoundary fallback={() => <Error500 />}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </Sentry.ErrorBoundary>
          </ConfigProvider>
        </PersistGate>
      </ReduxProvider>
    </Auth0Provider>
  );
};

const container = document.getElementById("root");
const root = createRoot(container!);

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //
root.render(
  <EnvProvider>
    <RootComponent />
  </EnvProvider>
);
// } else {
//   root.render(<UnSecuredPageWarning />);
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
