import { appENV, appENVMapping } from "constants/ConstantVariables";
import { Role } from "../types/employee"
import moment from "moment";

const checkMFADeadline = (roles: Role[]) => {
  const now = moment();

  const isDeadlinePassed = roles?.find(role => {
    const employeeDeadline = role.TwoFactorAuthenticationEmployeeDeadline && moment(role.TwoFactorAuthenticationEmployeeDeadline).isValid() && moment(role.TwoFactorAuthenticationEmployeeDeadline).isBefore(now);
    const employerDeadline = role.TwoFactorAuthenticationEmployerDeadline && moment(role.TwoFactorAuthenticationEmployerDeadline).isValid() && moment(role.TwoFactorAuthenticationEmployerDeadline).isBefore(now);
  
    return employeeDeadline || employerDeadline;
  });

  return !!isDeadlinePassed;
};

const getENVMapping = () => {
  const appEnv = process.env.REACT_APP_ENV?.toLowerCase();
  let envValue = appENVMapping.DEVELOPMENT;
  switch (appEnv) {
    case appENV.DEVELOPMENT:
      envValue = appENVMapping.DEVELOPMENT
      break;
    case appENV.STAGING:
      envValue = appENVMapping.STAGING
      break;
    case appENV.PRODUCTION:
      envValue = appENVMapping.PRODUCTION
      break;
    default:
      envValue = appENVMapping.LOCAL
  }
  return envValue
}

export {
  checkMFADeadline,
  getENVMapping
};