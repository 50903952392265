import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./baseQuery";
import { ApiConstant } from "../../constants/ApiConstants";
export const NOTIFICATION_API_REDUCER_KEY = "pushNotificationApi";

export const pushNotificationApi = createApi({
  reducerPath: NOTIFICATION_API_REDUCER_KEY,
  baseQuery: baseQuery,
  tagTypes: ["Notifications"],
  endpoints: (builder) => ({
    broadcastNotification: builder.mutation<
      any,
      {
        title: string;
        description: string;
        clickAction: string;
      }
    >({
      query: (notification) => ({
        url: ApiConstant.BROADCAST_NOTIFICATION,
        method: "POST",
        body: notification,
      }),
    }),

    getAllNotificationTemplates: builder.query<
      {
        statusCode: number;
        message: string;
        data: Array<{
          ID: string;
          Title: string;
          Description: string;
          SentBy?: string | null;
          Status?: string | null;
          SentOn?: string | null;
        }>;
        pageSize: number;
        pageNo: number;
        totalRecords: number;
      },
      {
        pageNo: number;
        size: number;
        searchText: string;
        sortColumn: string;
        sortOrder: string;
      }
    >({
      query: ({
        pageNo = 1,
        size = 10,
        searchText = "",
        sortColumn = "SentOn",
        sortOrder = "DESC",
      }) => {
        const response = {
          url: ApiConstant.GET_ALL_NOTIFICATION_TEMPLATE,
          method: "POST",
          body: {
            pageNo,
            size,
            searchText,
            sortColumn,
            sortOrder,
          },
        };
        return response;
      },
      providesTags: ["Notifications"],
    }),
  }),
});

export const {
  useBroadcastNotificationMutation,
  useGetAllNotificationTemplatesQuery,
} = pushNotificationApi;
