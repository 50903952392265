import {
  CalendarOutlined,
  DashboardOutlined,
  GroupOutlined,
  IdcardOutlined,
  SafetyOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  BellOutlined,
} from "@ant-design/icons";
import { useEffect, useMemo, useCallback, useState } from "react";
import { useGetModuleAccessInfoQuery } from "store/reducers/modules";
import { NavItemType } from "types/menu";
import { ModulesAccess } from "types/modules";
import { GenericCardProps } from "types/root";
import useAuth from "./useAuth";
import { isEmpty, isNull } from "lodash";
import removeDuplicates from "helper/removeDuplicates";
import { moduleConstant } from "constants/moduleConstants";

type NavMenu = {
  label: string;
  value: string;
  icon?: GenericCardProps["iconPrimary"] | string;
  url?: string;
  children?: NavItemType[];
};

const NAV_MENU: NavMenu[] = [
  {
    label: moduleConstant.DASHBOARD,
    value: "Dashboard",
    icon: DashboardOutlined,
    url: "/",
  },
  {
    label: moduleConstant.EMPLOYERS,
    value: "Employers",
    icon: IdcardOutlined,
    url: "/employer/employerList",
  },
  {
    label: moduleConstant.ROLES_AND_PERMISSIONS,
    value: "Role",
    icon: SafetyOutlined,
    url: "/role/roles",
  },
  {
    label: moduleConstant.ADMIN_USERS,
    value: "admin-user",
    icon: UserAddOutlined,
    url: "/admin-users/users",
  },
  {
    label: moduleConstant.EMPLOYEES,
    value: "Employees",
    icon: UsergroupAddOutlined,
    url: "/employees/employeesList",
  },
  {
    label: moduleConstant.PRODUCTS,
    value: "Products",
    icon: GroupOutlined,
    url: "/product-master/list",
  },
  {
    label: moduleConstant.EVENTS,
    value: "events",
    icon: IdcardOutlined,
    children: [
      {
        id: "webinars",
        title: moduleConstant.WEBINARS,
        type: "item",
        icon: CalendarOutlined,
        url: "/webinars/list",
        search: "Webinars",
      },
      {
        id: "seminars",
        title: moduleConstant.SEMINARS,
        type: "item",
        icon: CalendarOutlined,
        url: "/seminars/list",
        search: "Seminars",
      },
    ]
  },
  {
    label: moduleConstant.MASTER_DATA,
    value: "master-data",
    icon: IdcardOutlined,
    children: [
      {
        id: "scheme-types",
        title: moduleConstant.SCHEME_TYPES,
        type: "item",
        url: "/masterEntity/schemeType/list",
        search: "Master Data",
      },
      {
        id: "suppliers",
        title: moduleConstant.SUPPLIERS,
        type: "item",
        url: "/masterEntity/suppliers/list",
        search: "Master Data",
      },
      {
        id: "providers",
        title: moduleConstant.PROVIDERS,
        type: "item",
        url: "/masterEntity/providers/list",
        search: "Master Data",
      },
      {
        id: "investmentoption",
        title: moduleConstant.INVESTMENT_OPTIONS,
        type: "item",
        url: "/masterEntity/investmentoption/list",
        search: "Master Data",
      },
      {
        id: "pensionFund",
        title: moduleConstant.PENSION_FUNDS,
        type: "item",
        url: "/masterEntity/pensionFund/list",
        search: "Master Data",
      },
      {
        id: "pensionType",
        title: moduleConstant.PENSION_TYPES,
        type: "item",
        url: "/masterEntity/pensionType/list",
        search: "Master Data",
      },
      {
        id: "payPeriod",
        title: moduleConstant.PAY_PERIODS,
        type: "item",
        url: "/masterEntity/payPeriod/list",
        search: "Master Data",
      },
      {
        id: "schemesMaster",
        title: moduleConstant.SCHEMES,
        type: "item",
        url: "/masterEntity/scheme/list",
        search: "Master Data",
      },
      {
        id: "avcPlans",
        title: moduleConstant.AVC_PLANS,
        type: "item",
        url: "/masterEntity/avcplans/list",
        search: "Master Data",
      },
      {
        id: "privatePlans",
        title: moduleConstant.PRIVATE_PLANS,
        type: "item",
        url: "/masterEntity/privatePlans/list",
        search: "Master Data",
      },
      {
        id: "ruleMaster",
        title: moduleConstant.RULE_MASTER,
        type: "item",
        url: "/masterEntity/rules/list",
        search: "Master Data",
      }
    ],
  },
  {
    label: moduleConstant.AUDIT_LOGS,
    value: "audit-logs",
    icon: IdcardOutlined,
    children: [
      {
        id: "employers",
        title: moduleConstant.EMPLOYERS,
        type: "item",
        url: "/audit-logs/employers/list",
        search: "Employers",
      },
      {
        id: "employees",
        title: moduleConstant.EMPLOYEES,
        type: "item",
        url: "/audit-logs/employees/list",
        search: "Employees",
      },
      {
        id: "products",
        title: moduleConstant.PRODUCTS,
        type: "item",
        url: "/audit-logs/products/list",
        search: "Products",
      },
      {
        id: "master-data",
        title: moduleConstant.MASTER_DATA,
        type: "item",
        url: "/audit-logs/master-data/list",
        search: "Master Data",
      },
      {
        id: "hct-config",
        title: moduleConstant.HCT_CONFIG,
        type: "item",
        url: "/audit-logs/hct-config/list",
        search: "HCT Config",
      },
    ],
  },
  {
    label: moduleConstant.HCT_CONFIG,
    value: "hct-config",
    icon: IdcardOutlined,
    children: [
      {
        id: "consent",
        title: moduleConstant.CONSENT,
        type: "item",
        url: "/hct-config/consent/list",
        search: "Consent",
      },
      {
        id: "journey",
        title: moduleConstant.JOURNEY,
        type: "item",
        url: "/hct-config/journey/list",
        search: "Journey",
      },
      {
        id: "responses",
        title: moduleConstant.RESPONSES,
        type: "item",
        url: "/hct-config/responses/list",
        search: "Responses",
      },
      {
        id: "smart-engine-products",
        title: moduleConstant.SMART_ENGINE_PRODUCT,
        type: "item",
        url: "/hct-config/smart-engine-products/list",
        search: "Smart Engine: Products",
      },
    ],
  },
  {
    label: moduleConstant.CMS_PAGES,
    value: "cms-pages",
    icon: CalendarOutlined,
    children: [
      {
        id: "mmm-pages",
        title: moduleConstant.MMM_PAGES,
        type: "item",
        url: "/cmsPages/mmm-pages/list",
        search: "CMS Pages",
      },
      {
        id: "product-pages",
        title: moduleConstant.PRODUCT_PAGES,
        type: "item",
        url: "/cmsPages/product-pages/list",
        search: "CMS Pages",
      },
    ],
  },
  {
    label: moduleConstant.PUSH_NOTIFICATION,
    value: "push-notification",
    icon: BellOutlined,
    url: "/push-notifications",
  },
];

const useMenuItems = () => {
  const { token } = useAuth();
  const {
    data,
    isLoading = true,
    error,
  } = useGetModuleAccessInfoQuery({}, { skip: isEmpty(token) });
  const [menuItems, setMenuItems] = useState<NavItemType[]>([]);

  const orderArray = useMemo(() => {
    return NAV_MENU.map((item) => item.label);
  }, []);

  const customSort = useCallback(
    (a: ModulesAccess, b: ModulesAccess) => {
      const indexOfA = orderArray.indexOf(a.ParentModuleName ?? "");
      const indexOfB = orderArray.indexOf(b.ParentModuleName ?? "");

      // If both elements are found in the orderArray, compare their positions
      if (indexOfA !== -1 && indexOfB !== -1) {
        return indexOfA - indexOfB;
      }

      // If an element is not found in the orderArray, place it at the end
      if (indexOfA === -1) return 1;
      if (indexOfB === -1) return -1;

      return 0;
    },
    [orderArray]
  );

  const navItem = useCallback(
    (item: ModulesAccess) => {
      const newItem = NAV_MENU.find(
        (nav) => nav.label === item.ParentModuleName
      );
      if (newItem?.label === "HCT Config" || newItem?.label === "Events") {
        const children = newItem?.children?.filter((res) =>
          data?.data?.modulesAccess?.find(
            (d) => d.ChildModuleName === res.search && d.CanView
          )
        );
        return {
          id: newItem?.value,
          icon: newItem?.icon,
          type: "group",
          children: [
            {
              id: item.ParentModuleName,
              title: item.ParentModuleName,
              type: newItem?.children ? "collapse" : "item",
              url: newItem?.url,
              icon: newItem?.icon,
              children,
            },
          ],
        };
      } else {
        const children = newItem?.children?.filter((res) =>
          data?.data?.modulesAccess?.find(
            (d) => d.ParentModuleName === res.search && d.CanView
          )
        );
        return {
          id: newItem?.value,
          icon: newItem?.icon,
          type: "group",
          children: [
            {
              id: item.ParentModuleName,
              title: item.ParentModuleName,
              type: newItem?.children ? "collapse" : "item",
              url: newItem?.url,
              icon: newItem?.icon,
              children: children,
            },
          ],
        };
      }
    },
    [data?.data?.modulesAccess]
  );

  const permission = (item: string) => {
    return data?.data.modulesAccess.find((res) =>
      isNull(res.ParentID)
        ? res.ParentModuleName === item
        : res.ChildModuleName === item
    );
  };

  useEffect(() => {
    const arr =
      data?.data.modulesAccess.map((res) => ({
        ...res,
        id: res.ParentModuleName,
      })) ?? [];
    const filteredArray = removeDuplicates(arr).filter((item) =>
      orderArray.includes(item.ParentModuleName ?? "")
    );
    const sortedArray = filteredArray.sort(customSort);
    const newItems = sortedArray.map((item) => navItem(item));
    setMenuItems(newItems);
  }, [customSort, data?.data, navItem, orderArray]);

  return {
    menuItems,
    permission,
    isLoading,
    error,
    modules: data?.data.modulesAccess,
  };
};

export default useMenuItems;
