export const dateConstant = {
    dd_MM_yyyy: "dd-MM-yyyy",
    DD_MM_YYYY: "DD-MM-YYYY",
    DD_MMM_YYYY: "DD-MMM-YYYY",
    YYYY_MM_DD: "YYYY-MM-DD",
    DD_MM_YYYY_HH_mm: "DD-MM-YYYY, HH:mm",
    DD_MM_YYYY_HH_MM_A: "DD-MM-YYYY, hh:mm A",
    DD_MM_YYYY_HH_MM_a: "DD-MM-YYYY, hh:mm a",
    DD_MM_YYYY_H_MM_SS_a: "DD-MM-YYYY, h:mm:ss a",
};

export const appENV = {
  LOCAL: "local",
  DEVELOPMENT: "development",
  STAGING: "staging",
  PRODUCTION: "production"
}

export const appENVMapping = {
  LOCAL: "4",
  DEVELOPMENT: "1",
  STAGING: "2",
  PRODUCTION: "3"
}
